// VendorAuthorized.js

import React, { useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import {Link, useNavigate,useParams } from 'react-router-dom';
import axios from 'axios';
import config from '../config';
import Alert from "react-bootstrap/Alert";

const ResetBeforeLogin = () => {

     // Access the server URL
  const serverUrl = config.SERVER_URL;

  const [confirmPassword, setConfirmPassword] = useState("");

  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState(false);



  const navigate = useNavigate();


  const { token, email } = useParams();

  useEffect(() => {
    // You can now use 'token' and 'email' in your logic
    console.log('Token:', token);
    console.log('Email:', email);

    
    const checkResetToken = async () => {
        try {
          // Make an API request to your Laravel backend to validate the token and email
          const response = await axios.post(serverUrl + '/api/compare-vendor-credentials', {
            token,
            email,
          });
  
          console.log(response.data);
          // Check the response from the backend
          if (response.data.Result === "success") {
            // console.log('Token and email are valid');
            // Continue with your logic, e.g., render the password reset form
          } else {
            // console.log('Token and email are not valid');
            navigate('/vendor-login');
          }
        } catch (error) {
          console.error('Error checking reset token:', error.message);
          // Handle any errors that occur during the API request
          navigate('/vendor-login');
        }
      };
  
      // Call the function to check the reset token when the component mounts
      checkResetToken();

  }, [token, email]);



  const reset = async (e) => {

  e.preventDefault();


  if (password !== confirmPassword) {
    alert('Passwords do not match.');
  }


    if ( !password )
     {
        alert('Please fill in Password and Confirm Password fields.');
     }
     else
     {
         
        if (password !== confirmPassword)
         {
            alert('Passwords do not match.');
         }
         else
         {
               try {
            // Make an API request to add a record to the vendor_documents table
            await axios.post(
              serverUrl + '/api/vendor-reset-password-before',
              {
                api_token: token,
                email: email,
                password: password,
      
              },
              {
                headers: {
                //   Authorization: `Bearer ${cookies.auth_token}`,
                },
              }
            ).then((response) => {
              const data = response.data;
              console.log( response.data);
      
      
              if (data.Result === "success")
              {
                  setSuccess(true);
                  setPassword('');
                  setConfirmPassword('');
              } 
              else{
                  setError('There is some error, Please Try Again');
              }
      
            });
      
            
            
      
          } catch (error) {
            // Handle any errors that occur during the API request
            console.error('Error adding record to vendor_documents:', error.message);
          }  
         }


     


     }



  

   
  };




  return (
    <div className="container">
      <br />
     
     

     
      <br />

      <div className="row justify-content-center">
        <div className="col-md-6">
          <div className="card">
            <div className="card-body">
              <h2 className="card-title text-center mb-4">Reset Password</h2>

              {success && (
                    <Alert key="primary" variant="primary" >
                      Password Updated successfully!
                    </Alert>
                  )}

              {error && <div className="alert alert-danger">{error}</div>}

              <form onSubmit={reset}>
           
                <div className="mb-3">
                  <label htmlFor="password" className="form-label">
                    Password:
                  </label>
                  <input
                    type="password"
                    className="form-control"
                    id="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    // required
                  />
                </div>

                <div className="mb-3">
                    <label>
                        Confirm Password:</label>
                    <input
                    type="password"
                    className="form-control"
                    style={{ border: '1px solid #ccc' }}
                    name="confirmPassword"
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    />
                </div>

                {password !== confirmPassword && (
                    <div style={{ color: 'red' }}>Passwords do not match.</div>
                )}


                <button type="submit" className="btn btn-primary w-100">
                  Reset
                </button>
              </form>
              <div className="mt-3 text-center">
                <Link to="/vendor-login/">Back</Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      
        

    </div>
  );
};

export default ResetBeforeLogin;
