import React, { useEffect } from 'react';

function LiveChat() {
  useEffect(() => {
    function add_chatinline() {
      var hccid = 35259469;
      var nt = document.createElement("script");
      nt.async = true;
      nt.src = `https://mylivechat.com/chatinline.aspx?hccid=${hccid}`;
      var ct = document.getElementsByTagName("script")[0];
      ct.parentNode.insertBefore(nt, ct);
    }
    add_chatinline();

    // Clean up function to remove the script if needed
    return () => {
      // Remove the chat script when the component unmounts
      // This might not be necessary depending on the behavior you want
      // Example:
      // var chatScript = document.querySelector('script[src="https://mylivechat.com/chatinline.aspx?hccid=35259469"]');
      // chatScript.parentNode.removeChild(chatScript);
    };
  }, []);

  return (
    <div>
      {/* Your component's UI goes here */}
    </div>
  );
}

export default LiveChat;
