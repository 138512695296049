// Login.js

import React, { useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import {Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import config from '../config';
import Alert from "react-bootstrap/Alert";
import LoadingOverlay from "../loading/LoadingOverlay";

const ForgotPassword = () => {


     // Access the server URL
     const serverUrl = config.SERVER_URL;

     const [confirmPassword, setConfirmPassword] = useState("");
     const [isLoading, setIsLoading] = useState(false);

     const [email, setEmail] = useState("");
   
     const [password, setPassword] = useState('');
     const [error, setError] = useState(false);
     const [success, setSuccess] = useState(false);
   
     const [cookies, removeCookie] = useCookies(['auth_token']);
     const [cookies1, removeCookie1] = useCookies(['vendorID']);
     const [tenderDocuments, setTenderDocuments] = useState([]);
     const navigate = useNavigate();


     const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

     const [emailError, setEmailError] = useState('');

     // ... (Other component code)
   
     const validateEmail = () => {
       // Check if the email is not empty and does not match the pattern
       if (!emailPattern.test(email) && email.trim() !== '') {
         setEmailError('Please enter a valid email address.');
         setEmail('');
       } else {
         setEmailError(''); // Clear the error message when email is valid
       }
     };


     setTimeout(() => {
      setError(false);
     }, 20000);


    //  setTimeout(() => {
    //   setSuccess(false);
    //  }, 20000);


  const reset = async (e) => {

    e.preventDefault();

    setIsLoading(true);
  
  
    if (!email) {
      alert('Please fill in email required fields.');
      setIsLoading(false);
      setSuccess(false);
    }
    else
    {
             try {
              // Make an API request to add a record to the vendor_documents table
              await axios.post(
                serverUrl + '/api/vendor-forgot-password',
                {
                  Email: email,
        
                },
                {
                  headers: {
                    // Authorization: `Bearer ${cookies.auth_token}`,
                  },
                }
              ).then((response) => {
                const data = response.data;
                console.log( response.data);
        
        
                if (data.Result === "success")
                {
                    setSuccess(true);
                    setPassword('');
                    setConfirmPassword('');
                    setError(false);
                    setIsLoading(false);
                } 
                else{
                    setError(true);
                    setSuccess(false);
                    setIsLoading(false);
                   
                }
        
              });
        
              
              
        
            } catch (error) {
              // Handle any errors that occur during the API request
              setError('The Email was not found, Please Try Again');
              setIsLoading(false);
              setSuccess(false);
            }  
    }
  
 
           
       
          
           
  
  
       
  
  
       
  
  
  
    
  
     
    };

  return (
    <div className="container">
    <br />
   
   

    
    <br />

    <div className="row justify-content-center">
      <div className="col-md-6">
        <div className="card">
          <div className="card-body">
            <h2 className="card-title text-center mb-4">Reset Password</h2>

            {success && (
                  <Alert key="primary" variant="primary" >
                    We have e-mailed your password reset link
                  </Alert>
                )}

            {error && (
                     <Alert key="danger" variant="danger" >
                     Email was not found
                   </Alert>
                   
                )}

            {/* {error && <div className="alert alert-danger">The Email Was Not Found</div>} */}

            <form onSubmit={reset}>


            <div className="mb-3">
                <label>Email:</label>
                <input
                  type="text"
                  style={{ border: '1px solid #ccc' }}
                  name="email"
                  className="form-control"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  onBlur={() => validateEmail()}
                  pattern={emailPattern.source}  // Use the source property of the RegExp object
                  // title="Please enter a valid email address."
                />
                  {emailError && <div style={{ color: 'red' }}>{emailError}</div>}
              </div>
         
           

   

         


              <button type="submit" className="btn btn-primary w-100">
                Reset
              </button>
            </form>
            <div className="mt-3 text-center">
              <Link to="/vendor-login/">Back</Link>
            </div>
          </div>
        </div>
      </div>
    </div>
    
    {isLoading && <LoadingOverlay />}

  </div>
  );
};

export default ForgotPassword;
