import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';


const RegistrationSuccess = () => {
  

    return (
        <div className='container'>
           
           <br /><br /><br /><br /><br />

          <h2>Vendor Registered Successfully</h2>
          <p>Please wait until we approve your account.</p>

          <div className="mt-3 ">
                <Link to="/vendor-login">Login</Link>
              </div>

          <br /><br /><br /><br /><br />
        </div>
      );
 
};

export default RegistrationSuccess;
