// LoadingOverlay.js
import React from 'react';
import '../assets/css/loading-overlay.css';

const LoadingOverlay = () => (
  <div className="loading-overlay">
    <div className="loading-spinner"></div>
  </div>
);

export default LoadingOverlay;
