// VendorAuthorized.js

import React, { useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import {Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import config from '../config';
import Alert from "react-bootstrap/Alert";

const ResetPassword = () => {

     // Access the server URL
  const serverUrl = config.SERVER_URL;

  const [confirmPassword, setConfirmPassword] = useState("");

  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState(false);

  const [cookies, removeCookie] = useCookies(['auth_token']);
  const [cookies1, removeCookie1] = useCookies(['vendorID']);
  const [tenderDocuments, setTenderDocuments] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    // Check if the authentication token is undefined
    if (typeof cookies.auth_token === 'undefined' || cookies.auth_token === 'undefined') {
      // Redirect to the login page if not authorized
      navigate('/vendor-login');
    } else {
      // Fetch TenderDocuments when the component mounts
     
    }
  }, [cookies.auth_token, navigate]);

  // console.log(cookies1.vendorID);

  const handleLogout = () => {
    // Remove the 'auth_token' cookie when the user logs out
    removeCookie('auth_token');
    removeCookie1('vendorID');
    // Redirect the user to the login page after logout
    navigate('/vendor-login', { replace: true });
  };






  const reset = async (e) => {

  e.preventDefault();


  if (password !== confirmPassword) {
    alert('Passwords do not match.');
  }


    if ( !password )
     {
        alert('Please fill in Password and Confirm Password fields.');
     }
     else
     {
         
        if (password !== confirmPassword)
         {
            alert('Passwords do not match.');
         }
         else
         {
               try {
            // Make an API request to add a record to the vendor_documents table
            await axios.post(
              serverUrl + '/api/vendor-reset-password',
              {
                api_token: cookies.auth_token,
                vendor_id: cookies1.vendorID,
                password: password,
      
              },
              {
                headers: {
                  Authorization: `Bearer ${cookies.auth_token}`,
                },
              }
            ).then((response) => {
              const data = response.data;
              console.log( response.data);
      
      
              if (data.Result === "success")
              {
                  setSuccess(true);
                  setPassword('');
                  setConfirmPassword('');
              } 
              else{
                  setError('There is some error, Please Try Again');
              }
      
            });
      
            
            
      
          } catch (error) {
            // Handle any errors that occur during the API request
            console.error('Error adding record to vendor_documents:', error.message);
          }  
         }


     


     }



  

   
  };




  return (
    <div className="container">
      <br />
     
     

      <button style={{ float:"right" }} onClick={handleLogout}>Logout</button>
      <br />

      <div className="row justify-content-center">
        <div className="col-md-6">
          <div className="card">
            <div className="card-body">
              <h2 className="card-title text-center mb-4">Reset Password</h2>

              {success && (
                    <Alert key="primary" variant="primary" >
                      Password Updated successfully!
                    </Alert>
                  )}

              {error && <div className="alert alert-danger">{error}</div>}

              <form onSubmit={reset}>
           
                <div className="mb-3">
                  <label htmlFor="password" className="form-label">
                    Password:
                  </label>
                  <input
                    type="password"
                    className="form-control"
                    id="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    // required
                  />
                </div>

                <div className="mb-3">
                    <label>
                        Confirm Password:</label>
                    <input
                    type="password"
                    className="form-control"
                    style={{ border: '1px solid #ccc' }}
                    name="confirmPassword"
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    />
                </div>

                {password !== confirmPassword && (
                    <div style={{ color: 'red' }}>Passwords do not match.</div>
                )}


                <button type="submit" className="btn btn-primary w-100">
                  Reset
                </button>
              </form>
              <div className="mt-3 text-center">
                <Link to="/vendor-authorized/">Back</Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      
        

    </div>
  );
};

export default ResetPassword;
