// VendorAuthorized.js

import React, { useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import config from '../config';

const VendorAuthorized = () => {

     // Access the server URL
  const serverUrl = config.SERVER_URL;

  const [cookies, removeCookie] = useCookies(['auth_token']);
  const [cookies1, removeCookie1] = useCookies(['vendorID']);
  const [tenderDocuments, setTenderDocuments] = useState([]);
  const [tendersWithDocuments, setTendersWithDocuments] = useState([]);

  const [data, setData] = useState([]);
  const attachmentFiles = data.documents || [];


  const navigate = useNavigate();

  useEffect(() => {
    // Check if the authentication token is undefined
    if (typeof cookies.auth_token === 'undefined' || cookies.auth_token === 'undefined') {
      // Redirect to the login page if not authorized
      navigate('/vendor-login');
    } else {
      // Fetch TenderDocuments when the component mounts
      fetchTenderDocuments();
    }

  
    
  }, [cookies.auth_token, navigate]);

  // console.log(cookies1.vendorID);

  const handleLogout = () => {
    // Remove the 'auth_token' cookie when the user logs out
    removeCookie('auth_token');
    removeCookie1('vendorID');
    // Redirect the user to the login page after logout
    navigate('/vendor-login', { replace: true });
  };


  const reset = () => {
  
    navigate('/reset-password', { replace: true });
  };


  // axios.get('https://admin.dabs.af/api/tender-documents'

  const fetchTenderDocuments = async () => {
    try {
      // Make an API request to fetch TenderDocuments
      const response = await axios.get(serverUrl + '/api/tender-documents', {
        headers: {
          Authorization: `Bearer ${cookies.auth_token}`,
        },
      });

      console.log(response.data);
      // Set the fetched TenderDocuments in the state
      setTenderDocuments(response.data);
      setTendersWithDocuments(response.data.documents);
      setData(response.data);
    } catch (error) {
      // Handle any errors that occur during the API request
      console.error('Error fetching TenderDocuments:', error.message);
    }
  };



  const handleDownload = async (document) => {
    try {
      // Make an API request to add a record to the vendor_documents table
      await axios.post(
        serverUrl + '/api/vendor-documents',
        {
          tender_document_id: document.tender_id,
          vendor_id: cookies1.vendorID/* Replace with the vendor's ID or any identifier */,
         
        },
        {
          headers: {
            Authorization: `Bearer ${cookies.auth_token}`,
          },
        }
      ).then((response) => console.log( response.data));

      // Trigger the file download
      // window.location.href = `${process.env.REACT_APP_SERVER}/api/files/${document.file}`;
       // Trigger the file download with auth_token as a query parameter
       downloadFile(document.file, cookies.auth_token);

    } catch (error) {
      // Handle any errors that occur during the API request
      console.error('Error adding record to vendor_documents:', error.message);
    }
  };

  const downloadFile = (fileName, authToken) => {
    // Redirect to download URL with auth_token as a query parameter
    window.location.href = `${process.env.REACT_APP_SERVER}/api/files/${fileName}?auth_token=${authToken}`;
};


  return (
    <div className="container">
      <br />
      <h2>Vendor Authorized Page</h2>
      <p>This page can only be accessed by authorized users.</p>

     
      <div style={{ display: 'flex', justifyContent: 'space-between',maxWidth:'100%',float:'right' }}>
        <button className="btn btn-primary" onClick={() => reset()}>
          Change Password
        </button> &nbsp;&nbsp;
        <button className="btn btn-warning" onClick={handleLogout}>Logout</button>
      </div>
      <br />

      <h3>SBD</h3>
      
      {tenderDocuments.length > 0 ? (
  <table className="table table-striped">
    <thead>
      <tr>
        <th>TenderID</th>
        <th>Ref No</th>
        <th>Title</th>
        <th>Download</th>
      </tr>
    </thead>
    <tbody>
      {tenderDocuments.map((tender) => (
        <tr key={tender.tender_id}>
          <td>{tender.id}</td>
          <td>{tender.refno}</td>
          <td>{tender.title}</td>
          <td>
            {Array.isArray(tender.documents)
              ? (
                <ul>
                  {tender.documents.map((document) => (
                    <li key={document.tender_document_id}>
                      {/* {document.file}{' '} */}
                      <button
                        className="btn btn-primary mt-2"
                        onClick={() => handleDownload(document)}
                      >
                       {document.name}{' '}
                      </button>
                    </li>
                  ))}
                </ul>
              )
              : (
                <ul>
                  {Object.values(tender.documents).map((document) => (
                    <li key={document.tender_document_id}>
                      {/* {document.file}{' '} */}
                      <button
                        className="btn btn-primary mt-2"
                        onClick={() => handleDownload(document)}
                      >
                        {document.name}{' '}
                      </button>
                    </li>
                  ))}
                </ul>
              )
            }
          </td>
        </tr>
      ))}
    </tbody>
  </table>
) : (
  <p>Loading Tender Documents...</p>
)}


    </div>
  );
};

export default VendorAuthorized;
